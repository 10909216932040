<!--
 * @Author: lcz
 * @Date: 2022-12-29 16:29:35
 * @LastEditors: lcz
 * @LastEditTime: 2022-12-29 16:43:09
 * @Description: XXX
-->
<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '90%' }" v-if="show">
        <div class="popup-body">
          <div class="popup-title">已下单的商品</div>
          <div class="order-item" v-if="order.orderedItems">
            <div class="order-item_list">
              <div class="single-item-box" v-for="(item, index) in order.orderedItems" :key="index">
                <div class="single-item-right">
                  <div class="item-info">
                    <div class="item-info_name">{{ item.itemName }}</div>
                    <div class="item-info_price">{{ item.price | priceFilter }}</div>
                    <div class="item-info_qty">x{{ item.quantity }}</div>
                  </div>
                  <div class="item-desc" v-if="item.spec || item.make || item.taste || item.promoTag">
                    <span class="item-desc_text" v-if="item.spec">{{ item.spec }}</span>
                    <span class="item-desc_text" v-if="item.make">{{ item.make }}</span>
                    <span class="item-desc_text" v-if="item.taste">{{ item.taste }}</span>
                    <span class="item-desc_tag" v-if="item.promoTag && item.discountAmount > 0">{{ item.promoTag }}，已优惠{{ item.discountAmount }}元</span>
                  </div>
                  <div class="detail-selected" :class="{ active: item.detailShow }" v-if="(item.subCharges && item.subCharges.length > 0) || (item.subItems && item.subItems.length > 0)">
                    <div class="detail-selected-box" @click="toggleCartItemDetail(item)">
                      <div class="detail-selected-content">
                        <div class="result-content">
                          <span class="result-item" v-for="(sub, subIndex) in item.subItems" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                          <span class="result-item" v-for="(sub, subIndex) in item.subCharges" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                        </div>
                        <div class="collapse-btn">
                          <i class="iconfont iconfangxiang-xiangxia"></i>
                        </div>
                      </div>
                    </div>
                    <div class="detail-selected-box_fixed" @click="toggleCartItemDetail(item)">
                      <div class="detail-selected-content">
                        <div class="result-content">
                          <span class="result-item" v-for="(sub, subIndex) in item.subItems" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                          <span class="result-item" v-for="(sub, subIndex) in item.subCharges" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                        </div>
                        <span class="collapse-btn">
                          <i class="iconfont iconfangxiang-xiangshang"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <btn-footer rightBtnText="关闭" @onRightClick="closePopup"></btn-footer>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onBackdropClick" v-if="show"></div>
    </transition>
  </div>
</template>

<script>
import BtnFooter from '@/components/common/BtnFooter'
export default {
  name: 'PaymentOrderedItemPopup',
  components: { BtnFooter },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    data: { type: Object, required: true, default: () => {} }
  },
  data () {
    return {
      order: {
        orderedItems: []
      }
    }
  },
  watch: {
    data (val) {
      this.order = JSON.parse(JSON.stringify(this.data))
    }
  },
  filters: {
    priceFilter (val) {
      if (val === 0) {
        return '免费'
      }
      return '￥' + val
    },
    cartSubItemFilter (val) {
      if (!val.itemName) {
        return ''
      }
      let descList = []
      if (val.spec) {
        descList.push(val.spec)
      }
      if (val.make) {
        descList.push(val.make)
      }
      if (val.taste) {
        descList.push(val.taste)
      }
      let descStr = ''
      if (descList.length > 0) {
        descStr = `(${descList.join(' ')})`
      }
      return `· ${val.itemName}${descStr} x ${val.quantity}`
    }
  },
  methods: {
    toggleCartItemDetail (item) {
      if (!item.detailShow) {
        this.order.items.forEach((x) => {
          x.detailShow = false
        })
      }
      item.detailShow = !item.detailShow
      this.order = JSON.parse(JSON.stringify(this.order))
    },
    onBackdropClick () {
      this.closePopup()
    },
    closePopup () {
      this.$emit('update:show', false)
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}
.popup-body {
  width: 100%;
  height: 100%;
  padding: 60px 0 100px 0;
  overflow-y: auto;
  position: relative;
  .popup-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding-left: 24px ;
    color: $color-text-main;
    font-size: 28px;
    font-weight: bold;
    line-height: 60px;
  }
}
.order-item {
  width: 100%;
  background-color: $color-white;
  .order-item_list {
    width: 100%;
    transition: all 30s;
    .single-item-box {
      display: flex;
      width: 100%;
      padding: 24px ;
      align-items: center;
      .single-item-left {
        flex: 0 0 56px;
        margin-right: 16px;
        font-size: 0;
        img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }
      }
      .single-item-right {
        flex: 1;
        .item-info {
          display: flex;
          align-items: center;
          .item-info_name {
            flex: 1;
            font-size: 28px;
            color: $color-text-main;
            .item-name_desc {
              margin-left: 10px;
              line-height: 30px;
              font-size: 24px ;
              color: $color-text-sub;
            }
          }
          .item-info_price {
            flex: 0;
            font-size: 28px;
            color: $color-text-main;
            white-space: nowrap;
          }
          .item-info_qty {
            flex: 0;
            min-width: 120px;
            text-align: right;
            font-size: 28px;
            color: $color-text-main;
            &.disabled {
              opacity: 0.5;
            }
            .item-info-qty-select_disabled {
              color: $color-primary;
              opacity: 0.7;
            }
          }
        }
        .item-desc {
          width: 100%;
          margin-top: 8px;
          .item-desc_text {
            margin-right: 10px;
            line-height: 30px;
            font-size: 24px ;
            color: $color-text-sub;
          }
          .item-desc_tag {
            display: inline-block;
            font-size: 24px ;
            padding: 4px  8px;
            color: $color-danger;
            border: 1px solid $color-danger;
            border-radius: 6px;
            transform: scale(0.66);
            transform-origin: center left;
          }
        }
      }
    }
  }
  .order-item_more {
    position: relative;
    width: 100%;
    padding: 24px ;
    text-align: center;
    font-size: 24px ;
    color: $color-text-sub;
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px ;
      right: 24px ;
      height: 1px;
      border-bottom: 1px solid $color-border;
    }
    .more-icon {
      margin-left: 10px;
      font-size: 28px;
    }
  }
  .order-item_amount {
    width: 100%;
    padding: 24px ;
    text-align: right;
    font-size: 30px;
    color: $color-text-main;
    .amount-value {
      margin-left: 36px;
      font-weight: bold;
    }
  }
}
.detail-selected {
  position: relative;
  width: 100%;
  margin-top: 8px;
  font-size: 24px ;
  &.active {
    .detail-selected-box {
      visibility: hidden;
    }
    .detail-selected-box_fixed {
      display: block;
      max-height: 500px;
      opacity: 1;
    }
  }
  .detail-selected-box {
    position: relative;
    width: 100%;
    .detail-selected-content {
      position: relative;
      display: flex;
      width: 100%;
      line-height: 30px;
      padding: 16px 42px 16px 12px;
      background-color: #f3f6fa;
      .result-content {
        height: 30px;
        line-height: 30px;
        overflow: hidden;
      }
    }
    .collapse-btn {
      position: absolute;
      top: 16px;
      right: 30px;
      z-index: 11;
    }
  }
  .detail-selected-box_fixed {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    z-index: 10;
    opacity: 0;
    transition: all 0.5s;
    .detail-selected-content {
      position: relative;
      display: flex;
      width: 100%;
      padding: 16px 12px 48px 12px;
      line-height: 36px;
      background-color: #f3f6fa;
      .collapse-btn {
        position: absolute;
        bottom: 10px;
        right: 30px;
        z-index: 11;
      }
    }
  }
  .result-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .result-item {
      flex: 0 0 auto;
      padding: 0 12px;
      margin-bottom: 8px;
      white-space: nowrap;
    }
  }
}
</style>
