<!--
 * @Author: lcz
 * @Date: 2022-12-29 13:59:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-28 21:51:11
 * @Description: 已退菜的商品弹框
-->
<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '90%' }" v-if="show">
        <div class="wh-popup-container">
          <div class="wh-popup-header">
            <div class="wh-popup-header_title">退款明细</div>
          </div>
          <div class="wh-popup-body" v-if="order.refundInfos">
            <div class="order-item" v-for="(item, index) in order.refundInfos" :key="index">
              <div class="refund-title">
                <div class="title-left">{{ item | refundTitleText }}：</div>
                <div class="title-right">
                  <div class="right-table">{{ order.tableName }}-{{ order.serviceName || '堂食' }}</div>
                  <div class="right-time">{{ order.orderTime }}</div>
                </div>
              </div>
              <!-- 退菜 -->
              <template v-if="item.refundType === 1 && item.weightRefund !== 1">
                <div class="order-item_list" v-if="item.refundItems && item.refundItems.length > 0">
                  <div class="single-item-box" v-for="(refundItem, refundIndex) in item.refundItems" :key="refundIndex">
                    <div class="single-item-right">
                      <div class="item-info">
                        <div class="item-info_name">{{ refundItem.itemName }}</div>
                        <div class="item-info_price">{{ refundItem.refundAmount | priceFilter }}</div>
                        <div class="item-info_qty">x{{ refundItem.quantity }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="refund-row">
                  <div class="row-left">退菜商品总金额：</div>
                  <div class="row-right">￥{{ item.totalRefundAmount }}</div>
                </div>
              </template>
              <!-- 打折 -->
              <template v-if="item.refundType === 2 && item.weightRefund !== 1">
                <div class="refund-row">
                  <div class="row-left">店员打折：</div>
                  <div class="row-right discount-text">{{ item.discountRate | discountRateText }}</div>
                </div>
                <div class="refund-row">
                  <div class="row-left">打折退款金额：</div>
                  <div class="row-right">￥{{ item.totalRefundAmount }}</div>
                </div>
              </template>
              <!-- 补核销 -->
              <template v-if="item.refundType === 3 && item.weightRefund !== 1">
                <div class="refund-row">
                  <div class="row-left">补核销后已退金额：</div>
                  <div class="row-right">￥{{ item.totalRefundAmount }}</div>
                </div>
              </template>
              <!-- 称重退款 -->
              <template v-if="item.weightRefund === 1">
                <div class="weight-row" v-for="(weightItem, weightIndex) in item.refundItems" :key="weightIndex">
                  <div class="row-left">{{ weightItem.itemName }}</div>
                  <div class="row-right">
                    <text class="right-text">称重差价退款：</text>
                    <text class="right-amount">￥{{ weightItem.refundAmount }}</text>
                    <text class="right-weight">（{{ weightItem.weight }}{{ weightItem.spec }}）</text>
                  </div>
                </div>
              </template>
              <div class="desc-box" v-if="getRefundDesc(item).length">
                <div class="refund-desc" v-for="(descItem, descIndex) in getRefundDesc(item)" :key="descIndex">{{ descItem }}</div>
              </div>
            </div>
          </div>
          <div class="wh-popup-footer">
            <btn-footer rightBtnText="关闭" @onRightClick="closePopup"></btn-footer>
          </div>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onBackdropClick" v-if="show"></div>
    </transition>
  </div>
</template>

<script>
import BtnFooter from '@/components/common/BtnFooter'
export default {
  name: 'PaymentRefundItemPopup',
  components: { BtnFooter },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    data: { type: Object, required: true, default: () => {} }
  },
  data () {
    return {
      order: {
        refundItems: []
      }
    }
  },
  watch: {
    data (val) {
      this.order = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {},
  filters: {
    refundTitleText(val) {
      if (val.refundType === 1 && val.weightRefund !== 1) {
        return '退菜明细'
      }
      if (val.refundType === 2 && val.weightRefund !== 1) {
        return '打折明细'
      }
      if (val.refundType === 3 && val.weightRefund !== 1) {
        return '补核销退差明细'
      }
      if (val.weightRefund === 1) {
        return '称重退差明细'
      }
    },
    priceFilter(val) {
      if (val === 0) {
        return '免费'
      }
      return '￥' + val
    },
    discountRateText(val) {
      if (!val) {
        return '免单'
      } else {
        return `${val / 10} 折`
      }
    }
  },
  methods: {
    /**
     * 退款话术
     */
    getRefundDesc(val) {
      let descList = []
      // refundAmount：退款金额
      // fundAmount：消费金
      // storeValueAmount：储值卡
      // payCode：1现金2刷卡3微信4支付宝
      // status：1已退还0未退
      if (val.refundAmount > 0 && val.storeValueAmount <= 0) {
        if (val.payCode === 3 || val.payCode === 4) {
          descList.push(`${val.refundAmount}元退款将在72小时内原路返还至付款账户`)
        } else if (val.payCode === 1) {
          // 现金
          if (val.status === 1) {
            descList.push(`${val.refundAmount}元现金已退还`)
          } else {
            descList.push(`服务员将退还您${val.refundAmount}元`)
          }
        } else {
          // 刷卡
          if (val.status === 1) {
            descList.push(`${val.refundAmount}元已退还`)
          } else {
            descList.push(`服务员将为您重新刷卡、退还您${val.refundAmount}元`)
          }
        }
      }
      // 储值卡
      if (val.storeValueAmount > 0) {
        if (val.payCode === 3 || val.payCode === 4) {
          descList.push(`${(val.storeValueAmount + val.refundAmount).toFixed(2)}元退款将在72小时内原路返还至付款账户`)
        } else {
          descList.push(`${val.storeValueAmount}元退款将在72小时内原路返还至付款账户`)
        }
      }
      // 消费金
      if (val.fundAmount > 0) {
        descList.push(`${val.fundAmount}元退款已通过消费金的方式发送至您的悠订账户`)
      }
      return descList
    },
    onBackdropClick () {
      this.closePopup()
    },
    closePopup () {
      this.$emit('update:show', false)
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}
.order-item {
  width: 100%;
  background-color: $color-white;
  margin-bottom: 36px;
  padding: 0 24px ;
  .refund-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px  0 8px 0;
    border-bottom: 1px solid $color-border;
    .title-left {
      font-size: 28px;
      color: $color-text-main;
    }
    .title-right {
      .right-table {
        font-size: 28px;
        color: $color-text-main;
        text-align: right;
      }
      .right-time {
        font-size: 24px ;
        color: $color-text-sub;
        text-align: right;
      }
    }
  }
  .order-item_list {
    width: 100%;
    transition: all 30s;

    .single-item-box {
      display: flex;
      width: 100%;
      padding: 24px  0;
      align-items: center;
      border-bottom: 1px solid $color-border;

      .single-item-left {
        flex: 0 0 56px;
        margin-right: 16px;
        font-size: 0;

        .item-image {
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }
      }

      .single-item-right {
        flex: 1;

        .item-info {
          display: flex;
          align-items: center;

          .item-info_name {
            flex: 1;
            font-size: 28px;
            color: $color-text-main;

            .item-name_desc {
              margin-left: 10px;
              line-height: 30px;
              font-size: 24px ;
              color: $color-text-sub;
            }
          }

          .item-info_price {
            flex: 0;
            font-size: 28px;
            color: $color-text-main;
            white-space: nowrap;
          }

          .item-info_qty {
            flex: 0;
            min-width: 120px;
            text-align: right;
            font-size: 28px;
            color: $color-text-main;
            /deep/ .input {
              justify-content: flex-end;
            }
          }
        }

        .item-desc {
          width: 100%;
          margin-top: 8px;

          .item-desc_text {
            margin-right: 10px;
            line-height: 30px;
            font-size: 24px ;
            color: $color-text-sub;
          }

          .item-desc_tag {
            display: inline-block;
            font-size: 24px ;
            padding: 4px 8px;
            color: $color-danger;
            border: 1px solid $color-danger;
            border-radius: 6px;
            transform: scale(0.66);
            transform-origin: center left;
          }
        }
      }
    }
  }
  .refund-row {
    padding: 24px  0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-border;
    .row-left {
      font-size: 28px;
      color: $color-text-main;
    }
    .row-right {
      font-size: 32px;
      font-weight: bold;
      color: $color-text-main;
    }
    .discount-text {
      font-size: 28px;
      font-weight: 400;
      color: $color-warning;
    }
  }
  .weight-row {
    padding: 24px  0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-border;
    .row-left {
      font-size: 28px;
      color: $color-text-main;
    }
    .row-right {
      display: flex;
      align-items: center;
      .right-text {
        font-size: 24px ;
        color: $color-text-main;
      }
      .right-amount {
        font-size: 28px;
        font-weight: bold;
        color: $color-warning;
      }
      .right-weight {
        font-size: 24px ;
        font-weight: bold;
        color: $color-text-main;
      }
    }
  }
  .desc-box {
    padding-top: 24px ;
  }
  .refund-desc {
    padding-bottom: 24px ;
    font-size: 24px ;
    color: $color-text-sub;
  }
}
</style>
